import React from "react"
import UseHttpRequest from "../../hooks/HttpRequestKural"
const KuralRandom = () => {
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      
          <span className="sr-only">Loading...</span>
      
    )
  }
  const data = allData.Kurals
  
  return (
    <div className="header-content pl-0 d-lg-flex justify-content-end">
      {data.map((item, index) => (
        <p key={index} 
          id="thirukuralTooltip"
          data-toggle="tooltip"
          data-placement="right"
          title={item.MuVaUrai}
          className="text-left"
        >
          {item.Line1}
          <br />
          {item.Line2}
        </p>
      ))
      }
    </div>
  )
}

export default KuralRandom
