import { Link, navigate } from "gatsby"
import React from "react"
import KuralRandom from "./kuralRandom"
import Dropdown from "react-bootstrap/Dropdown"
import { isLoggedIn, logout } from "../../services/auth"
const Header = () => {
  return (
    <>
      <div className="navbar-area position-sticky p-0">
        <div className="header-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 d-none d-lg-block">
                <div className="header-content">
                  <p>
                    கென்டக்கி தமிழ் சங்கம் <br />
                    Kentucky Tamil Sangam
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <KuralRandom />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <nav className="navbar navbar-expand-lg position-relative py-3 px-0 py-lg-0 mean-container">
            <Link to="/" className="navbar-brand">
              <img
                src="/img/kyts-logo.png"
                className="black-logo"
                alt="Kyts Logo"
              />
            </Link>
            <button
              type="button"
              data-toggle="collapse"
              className="navbar-toggler"
              data-target="#navbar-kyts"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div id="navbar-kyts" className="collapse navbar-collapse mean-nav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" activeClassName="active" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/about"
                    activeClassName="active"
                    className="nav-link"
                  >
                    About KYTS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/tamil-school"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Tamil School
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/kyts-events"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/gallery"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Gallery
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/sponsors"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Sponsors
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/kalanjiyum"
                    activeClassName="active"
                    className="nav-link"
                  >
                    களஞ்சியம்
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/blog"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="others-options">
                {isLoggedIn() ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle className="burger-menu py-2 px-3 mr-2 font-weight-bold rounded text-white" id="dropdown-basic">
                        My Account
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/members/profile">Profile</Dropdown.Item>
                        <Dropdown.Item href="/members/order-history">Order History</Dropdown.Item>
                        <Dropdown.Item href="/members/registered-events">Registered Events</Dropdown.Item>
                        <Dropdown.Item href="/" onClick={(event) => {
                          event.preventDefault()
                          logout(() => navigate(`/login`))
                        }}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </>
                ) : (
                  <>
                    <Link
                      className="burger-menu py-2 px-3 mr-2 font-weight-bold rounded text-white"
                      to="/login"
                    >
                      Login
                    </Link>

                    <Link
                      className="burger-menu py-2 px-3 mr-2 font-weight-bold rounded text-white"
                      to="/register"
                    >
                      Register
                    </Link>
                  </>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header
