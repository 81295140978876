import React from "react"
import { Link } from "gatsby"
const Footer = () => {
  return (
    <>
      <footer className="footer-section pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Contact info</h3>

                <div className="footer-info-contact">
                  <i className="flaticon-call" />
                  <h3>Call Us</h3>
                  <span>
                    Alagar &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-322-6878">502-322-6878</a>
                  </span>
                  <br />
                  <span>
                    Kalpana &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-432-9142">502-432-9142</a>
                  </span>
                  <br />
                  <span>
                    Senthil T &nbsp;&nbsp;&nbsp;&nbsp;: <a href="tel:+1(502)-650-5920">502-650-5920</a>
                  </span>
                  <br />
                  <span>
                    Saravanan : <a href="tel:+1(502)-819-6579">502-819-6579</a>
                  </span>
                </div>
                <div className="footer-info-contact">
                  <i className="flaticon-email" />
                  <h3>Email Us</h3>
                  <span>
                    <a href="mailto:kytamilsangam@gmail.com">
                      kytamilsangam@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Quick Links</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link
                      to="/about"
                      activeClassName="active"

                    >
                      About KYTS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/tamil-school"
                      activeClassName="active"

                    >
                      Tamil School
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/kyts-events"
                      activeClassName="active"

                    >
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/gallery"
                      activeClassName="active"

                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      activeClassName="active"

                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      activeClassName="active"

                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Members</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link
                      to="/register"
                      activeClassName="active"
                    >
                      Become a Member
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/login"
                      activeClassName="active"
                    >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/register-online-account"
                      activeClassName="active">
                      Create Online Account
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/PDFs/kyts_member_list.pdf"
                      activeClassName="active">
                      Members List
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Policies</h3>
                <ul className="footer-quick-links">
                  <li>
                    <Link
                      to="/PDFs/KyTSByLaw-12062018.pdf"
                      activeClassName="active" target="_blank"
                    >
                      By-law
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/PDFs/KyTSCulturalEventsPolicy.pdf"
                      activeClassName="active" target="_blank"
                    >
                      Cultural Events Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/legal/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area pb-4">
          <div className="container">
            <div className="copyright-area-content pt-4">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <p>
                    <i className="far fa-copyright" />
                    Copyright © {new Date().getFullYear()} KYTS. All Rights Reserved
                  </p>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <span>Follow us on : &nbsp;&nbsp;</span>
                      <Link to="https://www.facebook.com/tamil.sangam.56" target="_blank"><img src="/img/icon/facebook.png" /></Link>
                    </li>
                    <li>
                      <Link to="https://www.youtube.com/channel/UCIsVzmd0Uqunr41Llfo8ChA" target="_blank"><img src="/img/icon/youtube.png" /></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="go-top">
        <i className="bx bx-chevron-up" />
        <i className="bx bx-chevron-up" />
      </div>
    </>
  )
}

export default Footer
