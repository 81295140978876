export const isBrowser = () => typeof window !== "undefined"
export const getUser = () => 
    isBrowser() && window.sessionStorage.getItem("KyTSUser")
        ? JSON.parse(window.sessionStorage.getItem("KyTSUser"))
        : {}
const setUser = KyTSUser =>
    window.sessionStorage.setItem("KyTSUser", JSON.stringify(KyTSUser))

export const saveUserOnSessionStorage = (userData) => {    
    return setUser({
        KyTSUser: userData
    })
}


export const isLoggedIn = () => {
    
    const KyTSUser = getUser().KyTSUser
    console.log("isBrowser", isBrowser());
    console.log("KyTSUser", KyTSUser);
    return KyTSUser !== null ? !!KyTSUser : false
}
export const logout = callback => {
    setUser({})
    callback()
}